import Conviva from '@convivainc/conviva-js-coresdk';
import { ClientType } from '@mlbtv-clients/services';
import { WIRED, WIRELESS } from 'analytics/conviva/constants';
import fallback from 'store/config/fallback.json';

interface DeviceMetadata {
  deviceBrand: string;
  deviceManufacturer: string;
  deviceModel: string;
  operatingSystemName: string;
}

const {
  Constants: { DeviceCategory, DeviceMetadata, DeviceType },
} = Conviva;

const {
  BRAND,
  CATEGORY,
  MANUFACTURER,
  MODEL,
  OS_NAME,
  OS_VERSION,
  SCREEN_RESOLUTION_HEIGHT,
  SCREEN_RESOLUTION_WIDTH,
  TYPE,
} = DeviceMetadata;

interface Connection {
  state: 'connected' | 'diconnected';
}

interface DeviceInfo {
  brandName?: string;
  manufacturer?: string;
  modelName: string;
  version: string;
}

interface getStatusResponse {
  isInternetConnectionAvailable?: boolean;
  returnValue: boolean;
  wifi?: Connection;
  wired?: Connection;
}

interface getIDsResponse {
  idList?: ID[];
  returnValue: boolean;
}

interface getSystemSettings {
  returnValue: boolean;
  settings?: Settings;
}

interface ID {
  idType: string;
  idValue: string;
}

interface Settings {
  audioGuidance: 'on' | 'off';
  smartServiceCountryCode2: string;
}

export const getClientType = async () => ClientType.LG_WEBOS;

// TODO: [LGTV-32]
export const getDeviceAdParams = (adParams: Record<string, string>) => {
  delete adParams.rdid;
  return { ...adParams, is_lat: 1 };
};

export const getDeviceCountryCode = async () => {
  const apiCall: Promise<string> = new Promise((resolve) => {
    window.webOS.service.request('luna://com.webos.settingsservice', {
      method: 'getSystemSettings',
      onFailure() {
        resolve('');
      },
      onSuccess(response: getSystemSettings) {
        resolve(response.settings?.smartServiceCountryCode2?.toLowerCase() ?? '');
      },
      parameters: {
        category: 'option',
        keys: ['country', 'smartServiceCountryCode2'],
      },
    });
  });

  try {
    return await apiCall;
  } catch (error) {
    return '';
  }
};

export const getDeviceConnectionType = async () => {
  const apiCall: Promise<string> = new Promise((resolve) => {
    window.webOS.service.request('luna://com.palm.connectionmanager', {
      method: 'getStatus',
      onFailure() {
        resolve(WIRED);
      },
      onSuccess(response: getStatusResponse) {
        resolve(response.wifi?.state === 'connected' ? WIRELESS : WIRED);
      },
    });
  });

  try {
    return await apiCall;
  } catch (error) {
    return WIRED;
  }
};

export const getDeviceExit = () => {
  try {
    window.webOS.platformBack();
  } catch (e) {
    console.error(e);
  }
};

export const getDeviceId = async (deviceId: string) => {
  const knownDeviceId = deviceId;

  const apiCall: Promise<string> = new Promise((resolve) => {
    window.webOS.service.request('luna://com.webos.service.sm', {
      method: 'deviceid/getIDs',
      onFailure() {
        resolve(knownDeviceId);
      },
      onSuccess(response: getIDsResponse) {
        const lgudid = response.idList?.find((id) => id.idType === 'LGUDID');
        resolve(lgudid?.idValue ?? knownDeviceId);
      },
      parameters: {
        idType: ['LGUDID'],
      },
    });
  });

  try {
    return await apiCall;
  } catch (error) {
    return deviceId;
  }
};

export const getDeviceKeyboardIsOpen = async () => {
  try {
    return window.webOS.keyboard.isShowing();
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const getDeviceMetadata = async (deviceMetadata: DeviceMetadata) => {
  const { deviceBrand, deviceManufacturer, operatingSystemName } = deviceMetadata;

  const info: DeviceInfo = await new Promise((resolve) => {
    window.webOS.deviceInfo((device: DeviceInfo) => resolve(device));
  });

  return {
    [BRAND]: info.brandName ?? deviceBrand,
    [CATEGORY]: DeviceCategory.LG_TV,
    [MANUFACTURER]: info.manufacturer ?? deviceManufacturer,
    [MODEL]: info.modelName,
    [OS_NAME]: operatingSystemName,
    [OS_VERSION]: info.version,
    [SCREEN_RESOLUTION_HEIGHT]: window.screen.height,
    [SCREEN_RESOLUTION_WIDTH]: window.screen.width,
    [TYPE]: DeviceType.SMARTTV,
  };
};

// LG Documentation
// https://www.lg.com/ca_en/support/product-support/troubleshoot/help-library/cs-CT20098005-20150585049620/
const getDeviceModelYear = (modelName: string) => {
  const year = new Date().getFullYear().toString();

  if (modelName.startsWith('OLED')) {
    const char = modelName.charAt(7);
    switch (char) {
      case '4':
      case 'S':
        return '2024';
      case '3':
      case 'R':
        return '2023';
      case '2':
      case 'Q':
        return '2022';
      case '1':
      case 'P':
        return '2021';
      case 'X':
      case 'N':
        return '2020';
      default:
        return year;
    }
  }

  const char = modelName.charAt(3);
  switch (char) {
    case 'S':
      return '2024';
    case 'R':
      return '2023';
    case 'Q':
      return '2022';
    case 'P':
      return '2021';
    case 'N':
      return '2020';
    default:
      return year;
  }
};

export const getDeviceHasSpinner = async (
  hasSpinner: typeof fallback.featureFlags.animations.hasSpinner,
) => {
  const { hasAllModelsDisabled, hasDisabledModels } = hasSpinner;
  if (hasAllModelsDisabled) return false;

  const info: DeviceInfo = await new Promise((resolve) => {
    window.webOS.deviceInfo((device: DeviceInfo) => resolve(device));
  });

  const { modelName } = info;
  const modelYear = getDeviceModelYear(modelName ?? '');
  return (
    !(hasDisabledModels as string[]).includes(modelName) &&
    !(hasDisabledModels as string[]).includes(modelYear)
  );
};

export const getDeviceTTSEnabled = async () => {
  const apiCall: Promise<boolean> = new Promise((resolve) => {
    window.webOS.service.request('luna://com.webos.settingsservice', {
      method: 'getSystemSettings',
      onFailure() {
        resolve(false);
      },
      onSuccess(response: getSystemSettings) {
        const isTTSEnabled = response.settings?.audioGuidance === 'on';
        resolve(isTTSEnabled);
      },
      parameters: {
        category: 'option',
        keys: ['audioGuidance'],
      },
    });
  });

  try {
    return await apiCall;
  } catch (error) {
    return false;
  }
};
